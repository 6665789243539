import React,{ useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {FaRegEye} from "react-icons/fa";

function ModalFullScreen(){

    const [fullscreen, setFullscreen]=useState(true);
    const [show , setShow] = useState(false);

    function handleShow(breakpoint){
        setFullscreen(breakpoint);
        setShow(true);
    }

    return(
        <>
        
            <span onClick={()=> handleShow(true)}><FaRegEye /></span>
            <Modal show={show} fullscreen={fullscreen} onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Taxa String Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="container">
                        <div class="row row-cols-2 shadow p-4 mb-4 bg-white">
                            <div class="col">
                                <div>Taxon ID</div>
                            </div>
                            <div class="col">
                                <div>Taxa String</div>
                            </div>
                            
                            <div class="col">
                                <div><input type="text" value="514" /></div>
                            </div>
                            <div class="col">
                                <div><input type="text" value="Pasteurella" /></div>
                            </div>
                        </div>
                       
                        <div class="row row-cols-4 shadow p-4 mb-4 bg-white">
                            
                            <div class="col">
                                <div>Genus Name</div>
                            </div>
                            <div class="col">
                                <div>Prevalence</div>
                            </div>
                            <div class="col">
                                <div>Avg</div>
                            </div>
                            <div class="col">
                            <div>Reference Range</div>
                            </div>

                            <div class="col">
                                <div><input type="text" value="Pasteurella" /></div>
                            </div>
                            <div class="col">
                                <div><input type="text" value="" /></div>
                            </div>
                            <div class="col">
                                <div><input type="text" value="" /></div>
                            </div>
                            <div class="col">
                            <div><input type="text" value=""/></div>
                            </div>
                       
                        </div>
                       
                        <div class="row shadow p-4 mb-4 bg-white">
                        <div class="row">
                            <div class="col">
                                0_Median
                            </div>
                            <div class="col">
                                Low 2.5(pct)
                            </div>
                            <div class="col">
                                Low 10(pct)
                            </div>
                            <div class="col">
                                High 90(pct)
                            </div>
                            <div class="col">
                                High 97.5(pct)
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                        </div>
                        <div class="row" style={{'margin-top':'10px'}}>
                            <div class="col">
                                F (0-2.5)
                            </div>
                            <div class="col">
                                F (2.5-10)
                            </div>
                            <div class="col">
                                F (10-90)
                            </div>
                            <div class="col">
                                F (90-97.5)
                            </div>
                            <div class="col">
                                F (Above 97.5)
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                Prevalence
                            </div>
                            <div class="col">
                                Flag Pattern
                            </div>
                            <div class="col">
                                Color Code
                            </div>
                            <div class="col">
                                Low
                            </div>
                            <div class="col">
                                High
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                            <div class="col">
                                <input type="text" value="0.5"/>
                            </div>
                        </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div>Description 
                                
                                </div>
                            </div>
                           
                        </div>
                        <div class="row">
                            <div class="col">
                                <div> 
                                    <textarea type="textarea" rows="4" cols="100">Bacteria belonging to the Pasteurella genus are 
                                    found in the oral cavity in about 55% of dogs, and about 80% of cats. Although associated with 
                                    the oral biofilm of healthy dogs and cats, these bacteria are more likely to be found in dogs 
                                    with more tartar (or “calculus”) on their teeth. Small dogs tend to have more 
                                    Pasteurella bacteria in their mouths compared to large dogs, and male cats tend to 
                                    have more Pasteurella bacteria compared to female cats.</textarea>
                                
                                </div>
                            </div>
                           
                        </div>

                    </div>
                    
                </Modal.Body>

            </Modal>
        </>

    );

}

export default ModalFullScreen;
// this funnctional component will provide upload UI with progress Bar, Card, Button and Message.
// First we will create a React template with React Hooks ( useState, useEffect) and import FileUploadService 
import React, {useState , useEffect } from "react";
import FileUploadService from "../services/FileUploadService";
import "./imageUpload.css";

const ImageUpload=()=>{

    // define the state using react hooks
    const [currentFile , setCurrentFile] = useState(undefined);
    const [previewImage , setPreviewImage] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");

    const [imageInfos, setimageInfos] = useState([]);

    const selectFile = (event) =>{
    
        setCurrentFile(event.target.files[0]);
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        setProgress(0);
        setMessage("");
    };
    // we use URL.createObjectURL() static method to get the image preview URL as previewImage. This method produces 
    // a DOMString including a URL describing the object provided in the parameter. The URL life is tied to the document 
    // in the window on which it was created
    // we call UploadService.upload() method on the currentFile with a call back. 
    // so create the following upload method

    const upload = () => {

        setProgress(0);

        FileUploadService.upload(currentFile, (event) => {
            setProgress(Math.round((100*event.loaded) / event.total));

        }) 
        .then((response)=>{

        })
        .then((files)=>{

        })
        .catch((err)=>{
            setProgress(0);

            if(err.response && err.response.data && err.response.data.message){
                    setMessage(err.response.data.message);
            }
            else{
                setMessage("couldn't upload the Image");
            }
            
            setCurrentFile(undefined);
        });

    };

    // use Effect hook to preview

    useEffect(()=>{

        FileUploadService.getFiles().then((response) => {
            setimageInfos(response.data);
        });
    }, []);

    // now write the upload UI code
    return (

            <div>
                <div className="row">
                    <div className="col-8">
                        <label className="btn btn-default p-0">
                            <input type="file" accept="image/*" onChange={selectFile}/>
                        </label>
                    </div>
                    <div className="col-4">
                        <button className="btn btn-success btn-sm" 
                                disabled={!currentFile}
                                onClick={upload}>
                            Upload
                        </button>
                    </div>
                </div>


            {currentFile && (

                <div className="progress my-3">
                    <div className="progress-bar progress-bar-info" 
                        role="progressbar" 
                        aria-valuenow={progress} 
                        aria-valuemin="0" 
                        aria-valuemax="100" 
                        style={{ width: progress + "%" }}>
                        {progress}%
                    </div>
                </div>
            )}
            {previewImage && (
                <div>
                    <img className="preview" src={previewImage} alt="" />
                </div>
            )}
        {message && (
            <div className="alert alert-secondary mt-3" role="alert">
                {message}
            </div>
        )

        }

        <div className="card mt-3">
            <div className="card-header">List Of images</div>
            <ul className="list-group list-group-flush">
                {imageInfos && 
                    imageInfos.map((img,index)=>(

                        <li className="list-group-item" key={index}>
                        <p>
                            <a href={img.url}>{img.name}</a>
                        </p>
                        <img src={img.url} alt={img.name} height="80px"/>
                        </li>

                    ))}
            </ul>
        </div>
    </div>
    );
};

export default ImageUpload;
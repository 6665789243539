import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaRegEye } from "react-icons/fa";
import Table from 'react-bootstrap/Table';

function SampleModalFullScreen({ sampleID }) {

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

    return (
        <>

            <span onClick={() => handleShow(true)}><FaRegEye /></span>
            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Sample Details Of {sampleID}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="container">
                        <div class="row row-cols-3">
                            <div class="col">
                                <div>Sample Type </div>
                            </div>
                            <div class="col">
                                <div>Extraction Type</div>
                            </div>
                            <div class="col">
                                <div>Amplicon Type</div>
                            </div>

                            <div class="col">
                                <div><input type="text" value="514" /></div>
                            </div>
                            <div class="col">
                                <div><input type="text" value="Pasteurella" /></div>
                            </div>
                            <div class="col">
                                <div><input type="text" value="Pasteurella" /></div>
                            </div>

                        </div>

                        <div class="row row-cols-3" style={{ 'margin-top': '10px' }}>


                            <div class="col">
                                <div>Study</div>
                            </div>
                            <div class="col">
                                <div>Sequebce Date</div>
                            </div>
                            <div class="col">
                                <div>Loaded Date</div>
                            </div>

                            <div class="col">
                                <div><input type="text" value="" /></div>
                            </div>
                            <div class="col">
                                <div><input type="text" value="" /></div>
                            </div>
                            <div class="col">
                                <div><input type="text" value="" /></div>
                            </div>

                        </div>
<div class="row" style={{'height':'10px'}}>

</div>
                        <div class="row">
                            <Table stripped bordered hover size="sm" >
                                <thead>
                                    <tr>
                                        <td>Taxon ID</td>
                                        <td>Taxon Genus name</td>
                                        <td>Value</td>
                                        <td>Count</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>374683</td>
                                        <td>Eisenbergiella</td>
                                        <td>0</td>
                                        <td>0</td>

                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                    </div>

                </Modal.Body>

            </Modal>
        </>

    );

}

export default SampleModalFullScreen;
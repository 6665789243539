import React from 'react';
import {Nav,NavLink,NavMenu} from "./NavbarElements";


const Navbar = () => {

    return(
        <>
        <Nav>
          <NavMenu>
          <NavLink to="/" activeStyle>
                Home
            </NavLink>
            <NavLink to="/sample" activeStyle>
                Samples
            </NavLink>
            <NavLink to="/report" activeStyle>
                Reports
            </NavLink>
            <NavLink to="/taxa" activeStyle>
                Taxa
            </NavLink>
            <NavLink to="/user" activeStyle>
                Users
            </NavLink>
            <NavLink to="/assesment" activeStyle>
                Assesment
            </NavLink>
           </NavMenu>
        </Nav>
        </>

    );
};

export default Navbar;
import * as React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from  '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Study ID Wise Sample Process Count',
    },
  },
};

 //var labels= ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


export function Barchart(props:any) {

  var labels;
  var dataValue;

  console.log("Date is"+props.date);
  console.log("studyid is"+props.studyid);
  labels=props.labeldata;
  dataValue=props.datavalue;

var data = {
  labels,
  datasets: [
    {
      label: 'Count',
     // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
     data:dataValue,
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

 

  return <Bar options={options} data={data} />;
}
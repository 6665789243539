import React from 'react'
import { FaFileDownload } from "react-icons/fa";
import SampleModalFullScreen from "../utilities/SampleModalFullScreen";

const Records = ({data}) => {
    
  return (  
    <table className="table">
        <thead>
                        <tr>
                            <td>Sample ID</td>
                            <td>Status</td>
                            <td>Created Date</td>
                            <td>Created By</td>
                            <td>Assigned To</td>
                            <td>Report Status</td>
                            <td>Report</td>
                            <td>Action</td>
                        </tr>
        </thead>
        <tbody>
            {data.map(item => (
                <tr>
                <td>{item.sampleID}</td>
                <td>Registered</td>
                <td>09/09/2022</td>
                <td>AnimalBiome</td>
                <td>abc@gmail.com</td>
                <td>NA</td>
                <td>NA</td>
                <td><FaFileDownload /><span><SampleModalFullScreen sampleID={item.sampleID} /></span></td>
            </tr>
            ))}
        </tbody>
    </table>
  ) 
}

export default Records  

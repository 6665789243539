import React from "react";

const Report = () => {

    return(
        <div>

            <p>Welcome To Report Page</p>
        </div>

    );

};

export default Report;
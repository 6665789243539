import React, { useEffect, useState, useMemo } from "react";
import './sample.css';
import { FaFileDownload } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.css';
import Table from 'react-bootstrap/Table';
import SampleModalFullScreen from "../utilities/SampleModalFullScreen";
import Pagination from "../paginationUtil/Pagination";
import Records from "../paginationUtil/Records";

//import data from "../paginationUtil/data.json";

let PageSize = 10;

function Sample() {

   // To hold the actual data
   const [data, setData] = useState([])
   const [loading, setLoading] = useState(true);

   const [currentPage, setCurrentPage] = useState(1);
   const [recordsPerPage] = useState(10);

   useEffect(()=>{
    fetch('http://localhost:8080/sample')
    .then((response)=> response.json())
    .then((data)=>{
        console.log("Response is"+data);
        setData(data);
        setLoading(false);
    })
    .catch((err)=>{
        console.log("Error is"+err);
    })

},[] )

   const indexOfLastRecord = currentPage * recordsPerPage;
   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
   const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
   const nPages = Math.ceil(data.length / recordsPerPage)

   return (
       <div className='container mt-5'>
           <div class="row">
                <div class="col">
                    Enter Sample ID <input type="text" value="" placeholder="SampleID"/>
                </div>

           </div>
           <Records data={currentRecords}/>
           <Pagination
               nPages={nPages}
               currentPage={currentPage}
               setCurrentPage={setCurrentPage}
           />
       </div>
   );

};

export default Sample;
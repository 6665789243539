import React from "react";
import ImageUpload from "../components/imageUpload";
import "bootstrap/dist/css/bootstrap.min.css";
//import "../components/imageUpload.css";


const Assesment = () => {

    return (
        <div className="container">
            Welcome To Image Assesment page

            <div className="content">
                <ImageUpload/>
            </div>

        </div>

    );
};

export default Assesment;
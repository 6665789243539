import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card"
import { FaAlignRight, FaUserAlt, FaSync } from "react-icons/fa";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Barchart } from "../charts/barchart";
import { Piechart } from "../charts/piechart";
import '../utilities/abloader.css';



const Home = () => {

    const [data, setData] = useState([]);
    const [usrType, setUsrType] = useState([]);
    const [msData, setMsData] = useState([]);
    const [isStart, setIsStart] = useState(false);
    var [startDate, setStartDate] = useState('');
    var [endDate, setEndDate] = useState('');
    const dateinputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    // const usrTypeRef = useRef(null);
    const labelData = ['vet', 'embark', 'customer', 'Donor', 'epicor', 'primal', 'NNN', 'Royal_Canin_date', 'Customer', 'SFRAW', 'FCGS_date',
        'Oral_swab_date', 'horseybiome_date']
    const dataValue = {
        'vet': 2000, 'embark': 800, 'customer': 3000, 'Donor': 500, 'epicor': 200, 'primal': 100, 'NNN': 200,
        'Royal_Canin_date': 300, 'Customer': 2000, 'SFRAW': 400, 'FCGS_date': 600,
        'Oral_swab_date': 40, 'horseybiome_date': 50
    };




    const handleChange1 = (e) => {
        setStartDate(e.target.value);
        console.log(startDate);
    };

    const handleChange2 = (e) => {
        setEndDate(e.target.value);
        console.log(endDate);
    };

    const handleChange3 = (e) => {
        setUsrType(e.target.value);
        // console.log("Selected Value"+e.target.value);

        console.log("user Type is" + usrType);

    }

    useEffect(() => {
        //fetch('https://jsonplaceholder.typicode.com/posts?_limit=10',{mode:'no-cors'})
        if (isStart) {
            console.log("Clicked");
            console.log("Start date is" + startDate);
            
            console.log("Start date is" + startDate);
            console.log("End Date" + endDate);
            setLoading(true);
            fetch('http://localhost:8080?' + new URLSearchParams({
                fromDate: startDate,
                toDate: endDate
            }))
                .then((response) => response.json())
                .then((data) => {
                    console.log("Response obtained");
                    console.log(data);
                    setData(data);
                    setLoading(false);
                    setIsStart(false);
                })
                .catch((err) => {
                    console.log("Entered into Error Block.");
                    console.log(err.message);
                })

            setLoading(true);
            fetch('http://localhost:8080/mcrb?' + new URLSearchParams({
                fromDate: startDate,
                toDate: endDate,
                userType: usrType
            }))
                .then((response) => response.json())
                .then((msdata) => {
                    console.log("Response obtained");
                    console.log(msdata);
                    setMsData(msdata);
                    setLoading(false);
                    setIsStart(false);
                })
                .catch((err) => {
                    console.log("Entered into Error Block.");
                    console.log(err.message);
                })


        } else {
            console.log("not Clicked");

            setLoading(true);
            fetch('http://localhost:8080?' + new URLSearchParams({
                fromDate: startDate,
                toDate: endDate
            }))
                .then((response) => response.json())
                .then((data) => {
                    console.log("Response obtained");
                    console.log(data);
                    setData(data);
                    setLoading(false);
                    setIsStart(false);
                })
                .catch((err) => {
                    console.log("Entered into Error Block.");
                    console.log(err.message);
                })

            fetch('http://localhost:8080/mcrb?' + new URLSearchParams({
                fromDate: startDate,
                toDate: endDate,
                userType: usrType
            }))
                .then((response) => response.json())
                .then((msdata) => {
                    console.log("Response obtained");
                    console.log(msdata);
                    setMsData(msdata);
                    setIsStart(false);
                })
                .catch((err) => {
                    console.log("Entered into Error Block.");
                    console.log(err.message);
                })
        }
    }, [isStart]);

    return (
        <>
            {loading ? (

                <div class="loader-container">
                    <div class="spinner"></div>
                </div>
            ) : (

                <div class="container">

                    <div class="row shadow p-4 mb-4 bg-white">
                        <div class="border" style={{ 'margin-top': '12px', 'padding-top': '17px' }}>
                            <div class="border" style={{
                                'display': 'flex', 'align-items': 'center', 'justify-content': 'right',
                                'background-color': '#eee'
                            }}>
                                <span style={{ 'margin-right': '5px' }}>From:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange1} ref={dateinputRef} />
                                <span style={{ 'margin-right': '5px' }}>To:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange2} ref={dateinputRef} />


                                <span onClick={() => setIsStart(true)}><FaSync /></span>
                            </div>
                            <Row xs={1} md={4} className="g-4" style={{ 'margin-top': '-15px' }}>
                                <Col>
                                    <Card style={{ width: '18rem' }} className="mb-2" bg='info' border="dark">

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '60px', 'color': 'white' }}>{data.dogGutCount}</span></Card.Header>

                                        <Card.Body>
                                            {/* <Card.Title>No Of AB Customers</Card.Title> */}
                                            <Card.Text># Dog Gut Samples </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '18rem' }} className="mb-2" bg='info'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '60px', 'color': 'white' }}>{data.catGutCount}</span></Card.Header>

                                        <Card.Body>
                                            {/* <Card.Title>No Of AB Customers</Card.Title> */}
                                            <Card.Text># Cat Gut Samples</Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '18rem' }} className="mb-2" bg='info'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '60px', 'color': 'white' }}>{data.dogOralCount}</span></Card.Header>

                                        <Card.Body>

                                            <Card.Text># Dog Oral Samples </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ width: '18rem' }} className="mb-2" bg='info'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '60px', 'color': 'white' }}>{data.catOralCount}</span></Card.Header>

                                        <Card.Body>

                                            <Card.Text># Cat Oral Samples </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>
                            </Row>
                        </div>

                    </div>

                    <div class="row shadow p-4 mb-4 bg-white">

                        <div class="border" style={{ 'margin-top': '12px', 'padding-top': '17px' }}>
                            <div class="border" style={{
                                'display': 'flex', 'align-items': 'center', 'justify-content': 'right',
                                'background-color': '#eee'
                            }}>

                                <select style={{ 'margin-right': '5px', 'height': '22px' }} onChange={handleChange3}>
                                    <option value="UserType">User Type</option>
                                    <option value="User">User</option>
                                    <option value="Vet">Vet</option>
                                    <option value="Embark">Embark</option>
                                </select>
                                <button type="button" onClick={() => setIsStart(true)}>Refesh</button>
                            </div>
                            <Row xs={1} md={3} className="g-3" style={{ 'margin-top': '-9px' }}>
                                <Col>
                                    <Card style={{ width: '23rem' }} className="mb-2" bg='success'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '126px', 'color': 'white' }}>{msData.balanced}</span></Card.Header>

                                        <Card.Body>

                                            <Card.Text># Balanced </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '23rem' }} className="mb-2" bg='info'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '126px', 'color': 'white' }}> {msData.imbalanced}</span></Card.Header>

                                        <Card.Body>

                                            <Card.Text># Imbalanced </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '23rem' }} className="mb-2" bg='info'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '126px', 'color': 'white' }}> {msData.missingCommon}</span></Card.Header>

                                        <Card.Body>

                                            <Card.Text># Missing Core </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>


                            </Row>
                        </div>

                    </div>


                    <div class="row shadow p-4 mb-4 bg-white">

                        <div class="border" style={{ 'margin-top': '12px', 'padding-top': '17px' }}>
                            <div class="border" style={{
                                'display': 'flex', 'align-items': 'center', 'justify-content': 'right',
                                'background-color': '#eee'
                            }}>
                                <span style={{ 'margin-right': '5px' }}>From:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange1} ref={dateinputRef} />
                                <span style={{ 'margin-right': '5px' }}>To:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange2} ref={dateinputRef} />

                                <button type="button" onClick={() => setIsStart(true)}>Refesh</button>
                            </div>
                            <Row xs={1} md={2} className="g-2" style={{ 'margin-top': '-3px' }}>
                                <Col>
                                    <Card style={{ width: '35rem' }} className="mb-2" bg='success'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '60px', 'color': 'white' }}> {data.dogGutCount}</span></Card.Header>

                                        <Card.Body>

                                            <Card.Text># Harmful Bacteria </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '35rem' }} className="mb-2" bg='info'>

                                        <Card.Header><FaUserAlt />
                                            <span style={{ 'margin-left': '60px', 'color': 'white' }}> {data.catGutCount}</span></Card.Header>

                                        <Card.Body>

                                            <Card.Text># Dietary Insights </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>



                            </Row>
                        </div>


                    </div>

                    <div class="row shadow p-4 mb-4 bg-white">
                        <div class="border" style={{ 'margin-top': '12px', 'padding-top': '17px' }}>
                            <div class="border" style={{
                                'display': 'flex', 'align-items': 'center', 'justify-content': 'right',
                                'background-color': '#eee'
                            }}>
                                <span style={{ 'margin-right': '5px' }}>From:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange1} ref={dateinputRef} />
                                <span style={{ 'margin-right': '5px' }}>To:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange2} ref={dateinputRef} />

                                <button type="button" onClick={() => setIsStart(true)}>Refesh</button>
                            </div>
                            <Row xs={1} md={1} className="g-1">

                                <Col>
                                    <Barchart date={setStartDate} studyid={"Study"} labeldata={labelData} datavalue={dataValue} />
                                </Col>

                            </Row>
                        </div>
                    </div>


                    <div class="row">

                        <div class="border" style={{ 'margin-top': '12px', 'padding-top': '17px' }}>
                            <div class="border" style={{
                                'display': 'flex', 'align-items': 'center', 'justify-content': 'right',
                                'background-color': '#eee'
                            }}>
                                <span style={{ 'margin-right': '5px' }}>From:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange1} ref={dateinputRef} />
                                <span style={{ 'margin-right': '5px' }}>To:</span>
                                <input style={{ 'margin-right': '5px', 'height': '22px' }} type="date" onChange={handleChange2} ref={dateinputRef} />

                                <button type="button" onClick={() => setIsStart(true)}>Refesh</button>
                            </div>
                            <Row xs={1} md={2} className="g-2" style={{ 'margin-top': '-3px' }}>
                                <Col>
                                    <Card style={{ width: '35rem' }} className="mb-2" bg='success'>

                                        <Card.Header><FaUserAlt />
                                            {data.dogGutCount}</Card.Header>

                                        <Card.Body>

                                            <Card.Text># No Of After Samples ( Samples that are not pets first samples with AB) </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '35rem' }} className="mb-2" bg='info'>

                                        <Card.Header><FaUserAlt />
                                            {data.catGutCount}</Card.Header>

                                        <Card.Body>

                                            <Card.Text># No Of Fecal Image Submissions From API. </Card.Text>
                                            <Button variant="primary">View</Button>
                                        </Card.Body>

                                    </Card>
                                </Col>



                            </Row>
                        </div>

                    </div>


                </div>
            )}
        </>
    );
};

export default Home;
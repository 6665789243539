import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router,Routes,Route} from  'react-router-dom';
import Home from './pages/home'
import Report from './pages/report';
import Sample from './pages/sample';
import Taxa from './pages/taxa';
import Users from './pages/user';
import Assesment from './pages/Assesment';
import Navbar from './components/Navbar';


function App() {
  return (
    <div>
    <Router>
      <Navbar />
      {/* <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/samples">Samples</Link>
          </li>
          <li>
            <Link to="/users">Usres</Link>
          </li>
          <li>
            <Link to="/taxaDictionary">Taxa</Link>
          </li>
          <li>
            <Link to="/reports">Reports</Link>
          </li>
        </ul>
        <hr/> 
        </div>
 */}
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/sample" element={<Sample />}/>
          <Route path="/user" element={<Users />}/>
          <Route path="/taxa" element={<Taxa/>}/>
          <Route path="/report" element={<Report />}/>
          <Route path="/assesment" element={<Assesment />}/>
        </Routes>

    </Router>
    </div>
  );
}

export default App;


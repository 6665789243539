import React from "react";

const User = () => {
   return(
        <div>
            <p>Welcome To Users Dashboard</p>
        </div>
    );
};

export default User;

import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card"
import { FaAlignRight, FaUserAlt } from "react-icons/fa";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Barchart } from "../charts/barchart";
import './sample.css';
import {FaFileDownload} from "react-icons/fa";

import Table from 'react-bootstrap/Table';
import ModalPopup from "../utilities/ModalPopup";
import ModalFullScreen from "../utilities/ModalFullScreen";
import {FaSync} from "react-icons/fa"


const Taxa = () => {
    return(
        <div class='container'>
        <div class="row">
            <div class="border" style={{ 'margin-top': '12px', 'padding-top': '17px' }}>
                <div class="border" style={{
                    'display': 'flex', 'align-items': 'center', 'justify-content': 'right',
                    'background-color': '#eee'
                }}>
                  <input type="text"  value="Internal ID" style={{'margin-right':'5px', 'height':'21px'}}  />
                  <select style={{'margin-right':'5px', 'height':'21px'}}>
                            <option>Category</option>
                            <option>core taxa</option>
                            <option>non-core taxa</option>
                            <option>Pathobiont</option>
                            <option>Periodontal disease associated</option>
                            <option>Potentially disease-associated</option>
                            <option>Health Associated</option>
                            
                </select>
                <select style={{'margin-right':'5px', 'height':'21px'}}>
                            <option>Genus</option>
                            <option>Species</option>
                </select>
                <select style={{'margin-right':'5px', 'height':'21px'}}>
                            <option>Taxa Type</option>
                            <option>Key</option>
                            <option>Other</option>
                </select>
                <select style={{'margin-right':'5px', 'height':'21px'}}>
                            <option>Species</option>
                            <option>Dog</option>
                            <option>Cat</option>
                </select>
                <select style={{'margin-right':'5px', 'height':'21px'}}>
                            <option>Sample Type</option>
                            <option>Gut</option>
                            <option>Oral</option>
                </select>
                <select style={{'margin-right':'5px', 'height':'21px'}}>
                            <option>Sample Type</option>
                            <option>Gut</option>
                            <option>Oral</option>
                </select>
                <select style={{'margin-right':'5px', 'height':'21px'}}>
                            <option>Version</option>
                            <option>V1</option>
                            <option>V2</option>
                            <option>V3</option>

                </select>
                <FaSync />
                   
                </div>
                <Row xs={1} md={1} className="g-1" style={{ 'margin-top': '3px' }}>
                <div class="col-mod-12">
                <Table stripped bordered hover size="sm" > 
                    <thead>
                        <tr>
                            <td>Taxond ID</td>
                            <td>Internal ID</td>
                            <td>Display Name</td>
                            <td>Category</td>
                            <td>Taxa Type</td>
                            <td>Species</td>
                            <td>Sample Type</td>
                            <td>Version</td>
                            <td>Details</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>514</td>
                            <td>AB00000514</td>
                            <td>Display Name</td>
                            <td>Health Associated</td>
                            <td>Key</td>
                            <td>Dog</td>
                            <td>Oral</td>
                            <td>V2</td>
                            <td><ModalFullScreen /></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
                </Row>
            </div>

        </div>

    </div>
    );
};

export default Taxa;